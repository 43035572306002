import * as ScrollMagic from 'scrollmagic';
import { Luminous, LuminousGallery } from 'luminous-lightbox';

const luminousOpt = {
	caption: (trigger) => {
		return trigger.querySelector('img').getAttribute('alt');
	},
}
const luminousGalleryContent = document.querySelectorAll('.gallery__slider-link');
if( luminousGalleryContent.length > 0 ) {
	new LuminousGallery(luminousGalleryContent, {}, luminousOpt);
}

// init controller
const controller = new ScrollMagic.Controller();

// build scene
const getDuration = (id) => {
	let target = document.getElementById(id);
	let clientRect = target.getBoundingClientRect();
	return clientRect.top;
}
console.log(getDuration("colophon"));
const navScene = new ScrollMagic.Scene({
	triggerElement: "#main",
	triggerHook: "onLeave",
	duration: getDuration("colophon"),
})
	.setClassToggle("#navigation, #booking", "active")
	.addTo(controller);



