// luxy.init({
// 	wrapper: '#page',
// 	wrapperSpeed: '0.4'
// });
import Swiper from 'swiper/bundle';

let heroSwipeOption = {
	loop: true,
	effect: "fade",
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		waitForTransition: false,
	},
	speed: 2000,
}
new Swiper(".hero", heroSwipeOption);

// var swiper = new Swiper(".gallery__container", {
// 	slidesPerView: 1,
// 	spaceBetween: 10,
// 	slidesPerGroup: 1,
// 	loop: true,
// 	loopFillGroupWithBlank: true,
// 	autoplay: true,
// 	breakpoints: {
// 		800: {
// 			slidesPerView: 3,
// 			slidesPerGroup: 1,
// 		}
// 	},
// 	pagination: {
// 		el: ".gallery-pagination",
// 		type: "progressbar",
// 	},
// 	navigation: false,
// });

const gallerySpeed = 8000;
const galleryLength = document.querySelectorAll('.gallery__container .swiper-slide').length;

const changeTranslate = (swiper) => {
	let currentTranslate = swiper.getTranslate();
	let slideWidth = document.querySelector('.gallery__container .swiper-slide-active').offsetWidth;
	swiper.setTranslate(currentTranslate - slideWidth);
	swiper.setTransition(gallerySpeed);
}
const initGallery = () => {
	const gallerySwiper = new Swiper('.gallery__container', {
		slidesPerView: 'auto',
		spaceBetween: 8,
		loop: true,
		loopedSlides: galleryLength,
		speed: gallerySpeed,
		autoplay: {
			delay: 0,
			disableOnInteraction: false,
		},
		freeMode: {
			enabled: true,
			momentum: false,
		},
		grabCursor: true,
		breakpoints: {
			1025: {
				spaceBetween: 16,
			}
		},
		on: {
			touchEnd: (swiper) => {
				changeTranslate(swiper);
			}
		},
		pagination: {
			el: ".gallery-pagination",
			type: "progressbar",
		},
	});
}

window.addEventListener('load', function(){
	initGallery();
});